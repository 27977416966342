import * as React from 'react'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'

import Grid from '../partials/grid'
import Card from '../partials/card'

// Portfolio Cards Filtered Listing Component
const PortfolioList = ({ order, active }) => {
	return (
		<Grid>
			{order.map(({ project }) => {
				let category = false
				let industry = false

				project.categories.forEach(c => {
					if (active.includes(c.slug)) {
						category = true
					}
				})

				project.industries.forEach(c => {
					if (active.includes(c.slug)) {
						industry = true
					}
				})

				return (
					<motion.div
						key={`project_card_small_${project.slug}`}
						initial={false}
						animate={
							active.length !== 0
								? category || industry
									? 'show'
									: 'hide'
								: 'show'
						}
						variants={visibility}
					>
						<Card project={project} />
					</motion.div>
				)
			})}
		</Grid>
	)
}

PortfolioList.propTypes = {
	order: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			project: PropTypes.shape({
				slug: PropTypes.string.isRequired,
				categories: PropTypes.arrayOf(
					PropTypes.shape({ slug: PropTypes.string.isRequired })
				).isRequired,
				industries: PropTypes.arrayOf(
					PropTypes.shape({ slug: PropTypes.string.isRequired })
				).isRequired,
			}).isRequired,
		})
	).isRequired,
	active: PropTypes.arrayOf(PropTypes.string).isRequired,
}

// Framer Category Container Variants
const visibility = {
	show: {
		display: 'block',
		transition: { duration: 0.25 },
	},
	hide: {
		display: 'none',
		transition: { duration: 0.25 },
	},
}

export default PortfolioList
