import * as React from 'react'
import { Link } from 'gatsby'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'

// Portfolio Card Inline Filter Buttons
const PortfolioFilters = ({ filters, toggle, active, all }) => {
	return (
		<div className="flex flex-wrap">
			{filters.map(filter => (
				<motion.button
					key={`project_filter_${filter.slug}`}
					className="mr-10 my-5 px-10 py-5 rounded focus:outline-none"
					onClick={() => toggle(filter.slug)}
					initial={false}
					animate={active.includes(filter.slug) ? 'active' : 'inactive'}
					variants={visibility}
				>
					<h5>{filter.title}</h5>
				</motion.button>
			))}

			{/* Only Display All Link For Industries */}
			{all && (
				<Link
					to="/portfolio"
					className="mr-10 my-5 px-10 py-5 focus:outline-none"
				>
					<h5 className="hover:underline hover:text-blue">See All Projects</h5>
				</Link>
			)}
		</div>
	)
}

PortfolioFilters.propTypes = {
	filters: PropTypes.arrayOf(
		PropTypes.shape({
			slug: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
		})
	).isRequired,
	toggle: PropTypes.func.isRequired,
	active: PropTypes.arrayOf(PropTypes.string.isRequired),
	all: PropTypes.bool.isRequired,
}

// Filter Framer Animation Variants
const visibility = {
	active: {
		backgroundColor: '#333333',
		color: '#E5E5E5',
		transition: { duration: 0.15 },
	},
	inactive: { backgroundColor: '#FFFFFF', transition: { duration: 0.15 } },
}

export default PortfolioFilters
